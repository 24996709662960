<template>
  <div class="c-app">
    <TheSidebar ref="sidebar" />
    <div class="c-wrapper">
      <TheHeader />
      <div class="c-body">
        <b-overlay class="w-100" opacity="0.75" variant="light" :show="mainLoading">
          <main class="c-main h-100">
            <b-container
              fluid
              :class="[
                $route.name === 'Report Customer Detail'
                  ? 'report_customer_detail'
                  : '',
              ]"
            >
              <router-view />
              <Loading />
            </b-container>
          </main>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import Loading from "@/components/Loading";
import { mapGetters } from "vuex";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    Loading,
  },
  computed: {
    ...mapGetters({ mainLoading: "getMainLoading" }),
  },
  methods: {},
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.report_customer_detail {
  padding-right: 15px;
}
#loading {
  background: rgba(255, 255, 255, 0.39);
  .modal-content {
    background: unset;
    position: absolute;
    border: unset;
  }
  .modal-body {
    justify-content: center;
    align-content: center;
    display: flex;
  }
}
</style>
