<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-lg-3 d-lg-none"
      v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
      in-header
      class="ml-lg-3 d-md-down-none"
      v-c-emit-root-event:toggle-sidebar
    />
    <CHeaderBrand
      class="mx-auto d-lg-none logo-mobile"
      :src="logoImage"
      alt="CoreUI Logo"
    />
    <CHeaderNav class="d-md-down-none mr-auto"> </CHeaderNav>
    <CHeaderNav class="mr-lg-3">
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-lg-3">
      <CBreadcrumbRouter class="border-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },
  data() {
    return {
      lang: this.$cookies.get("language") || "th",
      isLoading: true,
      theme: {},
    };
  },
  computed: {
    logoImage() {
      return this.$store.state.theme.Logo;
    },
  },
  async created() {},
  methods: {
    changeLang(val) {
      this.lang = val;
      this.$cookies.set("language", val);
      this.$router.go();
    },
  },
};
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
}
</style>
