<template>
  <b-modal
    id="loading"
    :visible="show"
    no-close-on-backdrop
    centered
    hide-footer
    hide-header
  >
    <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.$EventBus.$on("showLoading", () => {
  
      this.show = true;
    });
    this.$EventBus.$on("hideLoading", () => {
      this.show = false;
    });
  },
};
</script>
