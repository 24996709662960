<template>
  <div>
    <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
    >
      <template #toggler>
        <CHeaderNavLink>
          <div v-if="name">
            <b-avatar
              variant="dark"
              id="avatarUser"
              :text="name.charAt(0)"
            ></b-avatar>
          </div>
          <div v-if="!name">
            <b-avatar variant="dark" id="avatarUser" text="A"></b-avatar>
          </div>
        </CHeaderNavLink>
      </template>
      <CDropdownItem @click="logout">
        <CIcon name="cil-lock-locked" />Logout
      </CDropdownItem>
    </CDropdown>

    <ModalConfirm
      id="logout"
      ref="confirmModal"
      text="You want to log out"
      @handler="deleteMsg"
    />
  </div>
</template>

<script>
import ModalConfirm from "@/components/modal/ModalConfirm";
export default {
  components: {
    ModalConfirm,
  },
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      name: "",
    };
  },
  async created() {
    await this.getName();
  },
  methods: {
    getName() {
      this.name = this.$store.state.auth.ShortProfile.userName;
    },
    async deleteMsg(value) {
      // await this.$store.dispatch("ActionLogout", {});
      // const data = this.$store.state.moduleConnectAPI.stateLogout;
      // if (data.result === 1) {
      //   this.getLogout(value);
      // }
      this.getLogout(value);
    },
    getLogout(value) {
      if (value === true) {
        this.$cookies.remove("back_office_admin_sale_order_token");
        // this.$cookies.remove("permission_menu_admin_sale_order");
        // localStorage.removeItem("back_office_admin_sale_order_permission");
        window.location.href = "/";
      }
    },
    logout() {
      this.$refs.confirmModal.show();
    },
    // logout: async function () {
    //   this.$swal({
    //     title: "You want to log out?",
    //     icon: "info",
    //     buttons: ["Cancel", "Yes"],
    //     dangerMode: true,
    //   }).then((logout) => {
    //     if (logout) {
    //       this.$cookies.remove("back_office_admin_sale_order_token");
    //       this.$cookies.remove("permission_menu_admin");
    //       window.location.href = "/";
    //     }
    //   });
    // },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
